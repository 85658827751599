import React, { useState } from 'react';

const CopyToClipboard = ({ text }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(text); // Копируем текст в буфер обмена
      setCopied(true); // Устанавливаем статус "скопировано"
      setTimeout(() => setCopied(false), 2000); // Сбрасываем статус через 2 секунды
    } catch (err) {
      console.error("Ошибка копирования текста:", err);
    }
  };

  return (
    <>
      <span
        onClick={handleCopy}
        style={{ cursor: 'pointer', color: copied ? 'green' : 'blue' }}
        title={copied ? 'Скопировано!' : 'Кликните, чтобы скопировать'}
      >
        {text}
      </span>
      {copied && <span style={{ marginLeft: '10px', color: 'green' }}>Скопировано!</span>}
    </>
  );
};

export default CopyToClipboard;
