import React from "react";
import CopyToClipboard from "./comp/Copytoclipboard";
import "bootstrap/dist/css/bootstrap.min.css";

const App = () => {
  const downloadVCard = () => {
    // Определяем имя файла в зависимости от устройства
    const userAgent = window.navigator.userAgent.toLowerCase();
    const isWindows = userAgent.indexOf("windows") !== -1;
    const vcfFilePath = isWindows ? "pavliuchenko-win.vcf" : "pavliuchenko.vcf";

    const link = document.createElement("a");
    link.href = vcfFilePath;
    link.download = isWindows ? "pavliuchenko_oleg_win.vcf" : "pavliuchenko_oleg.vcf";

    // Добавляем ссылку в DOM, кликаем, затем удаляем
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.target);
    const message = `Контакт от: ${data.get("name")}\nНомер: ${data.get("phone")}\nКомментарий: ${data.get("message")}`;
    window.open(`https://t.me/evilfaust?text=${encodeURIComponent(message)}`);
  };

  const emcotechClick = () => {
    window.location.href = 'https://emcotech.site';
  };

  return (
      <>
      <div className="card-container">
        <div className="row">
          <div className="col-lg-6">
          <img className="photo" src="photo.png" width="1500px" alt="Олег Павлюченко" />
          <h1 className="name">Олег Павлюченко</h1>
          <h3 className="company">ООО "Восточная горнорудная компания"</h3>
          <div className="footer">
            <img src="logo-emco.png" height="30px" alt="Логотип компании" className="logo"/>
          </div>
          </div>
          <div className="col-lg-6">
          <h2 className="role">Детский технопарк 
            <span
          onClick={emcotechClick}
          style={{cursor: 'pointer', color: 'blue', textDecoration: 'underline'}}
          >
              &nbsp; EMCO.TECH
          </span>
          <br/>Руководитель проекта</h2>
          <br/>
          <p className="contact-info text-left"><img src="phone2.png" width="10px" alt="phone"/> <CopyToClipboard text="+7 914 755 40 38" /></p>
          <p className="contact-info text-left"><img src="phone1.png" width="10px" alt="phone"/> <CopyToClipboard text="+7 920 872 23 21" /> </p>
          <p className="contact-info text-center"><CopyToClipboard text="pavlyuchenkooi@eastmining.ru" /></p>
          <div className="button-container">
          <a className="" href="https://wa.me/79208722321" target="_blank" rel="noopener noreferrer"><img src="wa.png" width="30px" alt="wa"/></a>
          <a className="" href="https://t.me/evilfaust" target="_blank" rel="noopener noreferrer"><img src="tg.png" width="30px" alt="tg"/></a>
          <a className="" onClick={downloadVCard}> <img src="contact.png" width="30px" alt="contact"/></a>
          </div>
          </div>
        </div>
      </div>
      <div className="card-container">
      <div className="form-container">
        <h3>Обратная связь</h3>
        <form onSubmit={handleSubmit}>
          <input type="text" name="name" placeholder="Ваше имя" required />
          <input type="tel" name="phone" placeholder="Ваш номер телефона" required />
          <input type="text" name="message" placeholder="Сообщение" required />
          <button type="submit" className="button">Отправить</button>
        </form>
      </div>
      </div>
      </>
  );
};

export default App;
